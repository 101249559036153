*{
    --bs-gutter-x: 1.5rem
}

.container {
    margin: 0 auto;
    padding: 0 1rem;
  }

.hellotxt {
    /* position: absolute; */
    font-size: 5em;
    text-align: center;
    color: rgba(255, 255, 255, 0.342);
    font-weight: bold;
}

body{
    background-color: #1D222A;
    color: #cfe5f7;
}

.body-top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .aboutme {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    text-align: center;
  }

.f-name{
    text-decoration: underline;
    text-decoration-color: #00ADB5;
    text-underline-offset: 1rem;
}

.l-name{
    color: #00ADB5;
}

.p-description{
    padding: 1rem 1rem 0 0;
}

.description {
    margin-top: 1rem;
  }

.body-img-container{
    background: linear-gradient(30deg, rgb(34 40 49) 5%, rgb(0 212 255 / 0%) 100%);
    content: "";
    width: 15rem;
    height: 15rem;
    transform: rotate(-45deg);
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    box-shadow: 0 0 10px 0 #00ADB5;
    overflow: hidden;
    z-index: 1;
    /* margin: 0 0 2rem 2rem;*/
  }

    .body-img-container img{
        width: 100%;
        height: 100%;
        border-radius: 2rem;
        z-index: 1;
    }

  .john-img-social {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }

    .john-img-social .john-img {
        width: 15rem;
        height: 15rem;
        border-radius: 50%;
        box-shadow: 0 0 10px 0 #00ADB5;
        overflow: hidden;
        z-index: 1;
        margin: 0 0 2rem 2rem;
    }

  .john{
    width: 15rem;
    height: 15rem;
    rotate: 45deg;
  }

  .line {
    background-color: #00ADB5;
    margin: 1rem;
    width: 4px;
    height: 150px;
    border-radius: 4px;
}

/* .aboutme{
    position: relative;
} */

.social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }

  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .icons a {
    font-size: 25px;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: linear-gradient(45deg, #1a89bc, #606966c5);
    color: #cfe5f7;
    text-align: center;
    margin-bottom: 0.5rem;
    transition: 1s;
  }

.icon{
    width: 2rem;
    height: 2rem;
    color: #00ADB5;
}

.icons a:hover {
    background: linear-gradient(45deg, #1a89bc, #606966c5);
    transform: rotate(360deg) scale(1.2);
}

/* Language: css */

/* Path: my-portfolio\src\components\css\footer.css */

.footer{
    background-color: #1D222A;
    color: #cfe5f7;
    padding: 1rem 0 1rem 0;
}

.footer-text{
    text-align: center;
}

.description{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.follow{
    transform: rotate(90deg);
    margin-bottom: 25px;
}
.empty{
    margin-left: 17rem;
}

.quote {
    margin-top: 2rem;
    text-align: center;
  }
  
  .quote-text {
    font-size: 2rem;
    font-weight: bold;
    padding: 1rem;
  }

.quote-text p{
    font-size: 2rem;
    text-align: center;
}

.quote-text p::before{
    content: open-quote;
}

.quote-text p::after{
    content: close-quote;
}

.quote-text p::before, .quote-text p::after{
    font-size: 3rem;
}


.quote-author {
    font-size: 1.5rem;
    margin-bottom: 0;
    color: #a1a4a8;
  }

.quote-author{
    font-size: 1.5rem;
    margin-bottom: 0;
    color: #a1a4a8;
}

/* Language: css */

.my-works{
    background-color: #1D222A;
    color: #cfe5f7;
    padding: 1rem 0 1rem 0;
}

.my-works h1{
    text-align: center;
    padding: 1rem;
}

.my-works h1::after{
    content: "";
    display: block;
    width: 100px;
    height: 5px;
    background-color: #00ADB5;
    margin: 0 auto;
}

.my-works h1::before{
    content: "";
    display: block;
    width: 100px;
    height: 5px;
    background-color: #00ADB5;
    margin: 0 auto;
    border-radius: 3px;
    margin-bottom: 1rem;
}

.my-works h1::before, .my-works h1::after{
    margin-top: 1rem;
    border-radius: 3px;
}

.my-works .works{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
}

.my-works .works .work{
    width: 30%;
    background-color: #1D222A;
    color: #cfe5f7;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px 0 #00ADB5;
}

.my-works .works .work .work-img{
    width: 100%;
    height: 100%;
    border-radius: 1rem;
}

.my-works .works .work .work-img img{
    width: 100%;
    height: 100%;
    border-radius: 1rem;
}

.my-works .works .work .work-description{
    padding: 1rem;
}

.my-works .works .work .work-description h2{
    text-align: center;
}

.my-works .works .work .work-description p{
    text-align: center;
}

.my-works .works .work .work-description .work-description-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.my-works .works .work .work-description .work-description-btn .work-description-btn-1{
    background-color: #00ADB5;
    color: #1D222A;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 1rem;
    border: none;
    cursor: pointer;
    transition: 0.5s;
}

.my-works .works .work .work-description .work-description-btn .work-description-btn-1:hover{
    background-color: #1D222A;
    color: #00ADB5;
    border: 1px solid #00ADB5;
}

.my-works .works .work .work-description .work-description-btn .work-description-btn-2{
    background-color: #00ADB5;
    color: #1D222A;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 1rem;
    border: none;
    cursor: pointer;
    transition: 0.5s;
}

/* row */
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.bg-2{
    background-color: #1D222A;
    color: #cfe5f7;
    padding: 1rem 0 3rem 0;
}

.work{
    background-color: #222831;
    width: 200px;
    height: 200px;
    box-shadow: #0c0d0e;
    border-radius: 5px;
    transition: 00.5s ease;
}

.work:hover{
    transform: scale(1.02);
}

/* column */
.column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.work p{
    text-align: center;
    text-justify: inter-word;
}

.skill-icon{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    color: #00ADB5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.flex{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.flex h2 {
    text-align: center;
    font-size: -webkit-xxx-large;
}

@media (min-width: 768px){
    .col-md-4 {
        flex: 0 0 auto;
        width: auto;
    }
}


/* skills section*/

.skills{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #222831;
    width: 100%;
    height: 100%;
}
.skills-bar{
    width: 80%;
    height: 100%;
    background: #151920;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    color: #cfe5f7;
    margin: 10px;
    padding: 25px 30px;
    backdrop-filter: blur(5px);
}

.skills-bar::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 266, 0.1);
    z-index: -1;
    transform: translateX(-100%);
    transition: 1s;
    border: none;
}

.skills-bar::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 266, 0.1);
    z-index: -1;
    transform: translateX(100%);
    transition: 1s;
}

.skills-bar:hover::before{
    transform: translateX(0);
}

.skills-bar:hover::after{
    transform: translateX(0);
}

.skills-bar:hover .skills-bar-percent{
    transform: translateX(0);
}

.skills-bar-percent{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-100%);
    transition: 0.5s;
}

.skills-bar-percent span{
    font-size: 1.5rem;
    font-weight: 700;
}

.skills-bar-percent span:nth-child(2){
    font-size: 1rem;
    font-weight: 400;
    margin-left: 10px;
}

.skills-bar-percent span:nth-child(3){
    font-size: 1rem;
    font-weight: 400;
    margin-left: 10px;
}

.skills-bar-percent span:nth-child(4){
    font-size: 1rem;
    font-weight: 400;
    margin-left: 10px;
}

.skills-bar-percent span:nth-child(5){
    font-size: 1rem;
    font-weight: 400;
    margin-left: 10px;
}

.skills-bar-percent span:nth-child(6){
    font-size: 1rem;
    font-weight: 400;
    margin-left: 10px;
}

.skills-bar-percent span:nth-child(7){
    font-size: 1rem;
    font-weight: 400;
    margin-left: 10px;
}  

.skills-bar .bar{
    margin: 25px 0;
}

.skills-bar .bar :first-child{
    margin-top: 0;
}

.bar{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.skills-bar .bar .info span{
    font-size: 15px;
    font-weight: 600;
}

.skills-bar .bar .progress-line{
    position: relative;
    height: 15px;
    width: 80%;
    background: #f0f0f0;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, .05),
    0px 1px rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    transform-origin: left;
    transform: scaleX(0);
    animation: animate 1s cubic-bezier(0.075, 0.82, 0.165, 1)forwards;
}

.skills-bar .bar .progress-line span{
    height: 100%;
    position: absolute;
    background-color: #00ADB5;
    border-radius: 10px;
    border-radius: 10px;
    transform-origin: left;
    transform: scaleX(0);
    animation: animate 1s 1s cubic-bezier(0.075, 0.82, 0.165, 1)forwards;
}

@keyframes animate {
    100%{
        transform: scaleX(1);
    }
}

.html span{
    width: 93%;
}

.css span{
    width: 84%;
}

.js span{
    width: 73%;
}

.java span{
    width: 40%;
}

.sql span{
    width: 50%;
}

.react span{
    width: 69%;
}

.ux span{
    width: 70%;
}


/* projects section */

.projects{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #222831;
    width: 100%;
    height: 100%;
}

.proj{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    min-height: 400px;
    grid-gap: 4rem;
    background-color: #1d222a;
    padding: 2rem;
}

.view-works{
    display: flex;
    justify-content: center;
    background-color: #1d222a;
    padding-bottom: 4rem;
}

.proj .col-md-4 .proj-pic{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #222831;
    padding: 20px;
    width: 300px;
    height: 300px;
    overflow: hidden;
    /* box-shadow: inset 20px 20px 20px rgba(0, 0, 0, .05), 
    25px 35px 20px rgba(0, 0, 0, 0.5),
    inset -20px -20px 25px rgba(255, 255, 255, 0.09);  */
    transition: 0.5s ease-in-out;
}


.proj .col-md-4:nth-child(1) .proj-pic{
    border-radius: 71% 29% 53% 47% / 50% 42% 58% 50%;
}

.proj .col-md-4:nth-child(2) .proj-pic{
    border-radius: 23% 77% 42% 58% / 50% 42% 58% 50% ;
}

.proj .col-md-4:nth-child(3) .proj-pic{
    border-radius: 50% 50% 36% 64% / 54% 28% 72% 46% ;
}

.proj .col-md-4:nth-child(4) .proj-pic{
    border-radius: 50% 50% 36% 64% / 54% 59% 41% 46% ;
}

.proj .col-md-4:nth-child(5) .proj-pic{
    border-radius: 50% 50% 40% 60% / 54% 42% 58% 46%  ;
}

.proj .col-md-4:nth-child(6) .proj-pic{
    border-radius: 32% 68% 40% 60% / 54% 37% 63% 46%   ;
}

.proj .col-md-4 .proj-pic:hover{
    border-radius: 10px;
}

.proj .col-md-4 .proj-pic img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: 0.5s ease-in-out;
}

/* .proj .col-md-4 .proj-pic::before{
    content: "";
    position: absolute;
    top: 50px;
    left: 90px;
    height: 30px;
    width: 30px;
    background-color: #ffffff;
    border-radius: 50%;
    opacity: 1;
}

.proj .col-md-4 .proj-pic::after{
    content: "";
    position: absolute;
    top: 90px;
    left: 110px;
    height: 12px;
    width: 12px;
    background-color: #ffffff;
    border-radius: 50%;
    opacity: 1;
} */

.view-works-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: #3e3c3c;
    /* color: #ffffff; */
    border-radius: 10px;
    text-decoration: none;
    transition: 0.3s;
    align-self: center;
    height: 40px;
    border: none;
    margin: 2rem;
}

/* contact section */

.contact{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #00ADB5;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.contact-item{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #22283100;
    width: 100%;
    height: 100%;
}

.contact-bg{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #1d222a;
    width: 400px;
    height: 400px;
    border-radius: 40px;
    transform: rotate(45deg);
}


/* .contact-bg::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1d222a;
    border-radius: 10px;
    transform: rotate(-45deg);
} */
/* 
.contact-bg::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1d222a;
    border-radius: 10px;
    transform: rotate(-45deg);
} */

.contact-bg2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #1d222a;
    position: absolute;
    top: 3rem;
    left: 27.rem;
}


@media screen and (max-width: 1000px) {
    .col-md-4 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      width: 100%;
      height: 100%;
    }
  }
  
  @media (min-width: 768px) {
    .container {
      margin: 0 auto;
      padding: 0 1rem;
      max-width: 1200px;
    }
  
    .hellotxt {
      font-size: 5em;
      text-align: center;
      color: rgba(255, 255, 255, 0.342);
      font-weight: bold;
      margin-top: 2rem;
    }
  
    .body-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;
    }
  
    .aboutme {
      margin-top: 2rem;
      text-align: center;
      display: flex;
      flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
  
    .description {
      margin-top: 1rem;
    }
  
    .john-img-social {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;
    }
  
    .social {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
    }
  
    .icons a {
      font-size: 25px;
      width: 35px;
      height: 35px;
      margin: 0 0.5rem;
      background: linear-gradient(45deg, #1a89bc, #606966c5);
      text-align: center;
      transition: 1s;
    }
  
    .icons a:hover {
        background: linear-gradient(45deg, #1a89bc, #606966c5);
        transform: rotate(360deg) scale(1.2);
    }
  
    .quote {
      margin-top: 2rem;
      text-align: center;
    }
  
    .quote-text {
      font-size: 2rem;
      font-weight: bold;
      padding: 1rem;
    }
  
    .quote-author {
      font-size: 1.5rem;
      margin-bottom: 0;
      color: #a1a4a8;
    }
  }
  
  .footer__social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .footer__social a {
    font-size: 24px;
    color: #cfe5f7;
    margin: 0 10px;
  }
  
  .footer__social a:hover {
    color: #00adb5;
  }
  
  .footer__copyright {
    text-align: center;
  }
  
  .footer__copyright p {
    font-size: 14px;
    margin: 0;
  }
  