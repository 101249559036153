body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background: #0000001e;
}

.nav {
  background: #0000001e;
  background-color: rgba(0, 0, 0, 0);
  color: gray;
  z-index: 1;
  --bs-link-color: #ffffff;
  --bs-nav-pills-link-active-bg: #ffffff17;
  --bs-nav-pills-link-active-color: #8f7d7d;
}

.navbar {
  background-color: #1d222a33;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-bottom: 1px solid #ffffff1a;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  transition: 0.5s;
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
}

.navbar:hover {
  background-color: #1d222a;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-bottom: 1px solid #ffffff1a;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  transition: 0.5s;
}

.p-name {
  font-family: cursive;
  font-weight: bolder;
  color: aliceblue;
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 10px;
}

.cv-btn {
  background: #00000073;
  color: #ffffff;
  border: 1px solid #ffffff45;
  border-radius: 5px;
  padding: 2px;
  margin: 0 10px;
  transition: 0.5s;
}

.cv-btn:hover {
  background: #ffffff;
  color: #423d3d;
  border: 1px solid #36343459;
}

.cv-btn a {
  color: #ffffff;
}

.cv-link {
  color: #ffffff;
  text-decoration: none;
  transition: 0.5s;
}

.cv-link:hover {
  color: #000000;
}

.nav-link {
  color: rgb(251 251 251 / 82%);
  transition: 0.5s;
  font-weight: bold;
}

.nav-link:hover {
  color: #ffffff;
  background: #ffffff00;
}

.container-fluid {
  justify-content: space-between;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 0;
  align-items: center;
  justify-content: space-between;
}

.navbar-toggler{
    border: 3px solid #ffffff;
    border-radius: 5px;
    background: #615e5e2a;
    color: #ffffff;
    transition: 0.5s;
}

.active{
    color: #ffffff;
    font-weight: bolder;
}

.navbar-nav .nav-link.active {
    color: #ffffff;
    font-weight: 800;
}

@media screen and (max-width: 993px) {
  .nav {
    background: #0000001e;
    background-color: rgba(0, 0, 0, 0);
    color: gray;
    z-index: 1;
    --bs-link-color: #ffffff;
    --bs-nav-pills-link-active-bg: #ffffff17;
    --bs-nav-pills-link-active-color: #8f7d7d;
  }

  .p-name {
    font-family: cursive;
    font-weight: bolder;
    color: aliceblue;
  }

  .logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 10px;
  }

  .cv-btn {
    background: #00000073;
    color: #cfe5f7;
    border: 1px solid #ffffff45;
    border-radius: 5px;
    padding: 2px;
    margin: 0 10px;
    transition: 0.5s;
  }

  .cv-btn:hover {
    background: #cfe5f7;
    color: #423d3d;
    border: 1px solid #36343459;
  }

  .cv-btn a {
    color: #cfe5f7;
  }

  .cv-link {
    color: #ffffff;
  }

  .nav-link {
    color: rgb(251 251 251 / 82%);
    transition: 0.5s;
  }
  #navbarNav {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .navbar-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .cv-btn {
    /* Remove the absolute positioning and right property */
    position: relative;
    right: auto;
    top: auto;
    /* Adjust the margin and padding for proper spacing */
    /* margin: 10px 0; */
    padding: 5px 10px;
    /* Update the transition duration for smoother animation */
    transition: 2s;
    justify-self: center;
  }
  .show {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .collapsing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 992px) {
    .cv-btn {
        margin-left: 10rem;
    }
}
