.container {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .contact {
    text-align: center;
    margin-top: 2rem;
  }
  
  .contact h1 {
    font-size: 3rem;
    font-weight: bold;
    color: #333;
  }
  
  .contact h2 {
    font-size: 1.5rem;
    color: #cfe5f7;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2rem;
  }
  
  .col-md-6 {
    width: 48%;
  }
  
  .contact-info {
    margin-bottom: 2rem;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .contact-item a {
    color: rgb(189, 189, 189);
    text-decoration: none;
    transition: 0.5s;
  }

    .contact-item a:hover {
        color: #cfe5f7;
    }
  
  .icon {
    font-size: 1.5rem;
    color: #cfe5f7;
    background: linear-gradient(45deg, #1a89bc, #606966c5);
    width: 35px;
    height: 35px;
    text-align: center;
    margin: 0 0.5rem;
    border-radius: 5px;
    transition: 1s;
  }
  
  .contact-text {
    color: #cfe5f7;
  }
  
  .contact-text h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .contact-text p {
    margin: 0;
    color: #777;
  }
  
  .contact-form {
    background-color: #1D222A;
    padding: 2rem;
    border-radius: 5px;
  }
  
  .contact-form .form-group {
    margin-bottom: 1rem;
  }
  
  .contact-form label {
    font-weight: bold;
    color: #cfe5f7;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .contact-form textarea {
    height: 120px;
  }
  
  .contact-form button {
    background-color: #333;
    color: #cfe5f7;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #555555;
  }
  